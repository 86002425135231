import "./logo.css";
import React, { useEffect } from "react";


const Logo = () => {
  useEffect(() => {
    const logoEl = document.querySelector(".logo-text");
    if (logoEl && logoEl.innerHTML.trim()) {
      const logoText = logoEl.textContent.trim(); // Capture the text content of the logo
      logoEl.innerHTML = ''; // Clear the original text
      
      // Define the Google colors
      const colors = ["#4285F4", "#EA4335", "#FBBC05", "#34A853"];

      for (let i = 0; i < logoText.length; i++) {
        const letter = document.createElement("h1");
        // Directly apply color from the array, cycling through with modulo
        letter.style.color = colors[i % colors.length];
        letter.textContent = logoText[i]; // Set letter text
        
        logoEl.appendChild(letter); // Append the letter to the logo container
      }
    }
  }, []);

  return (
    <div className="search-logo" style={{ position: 'relative', textAlign: 'center', marginTop: '20px' }}>
      {/* Image positioned absolutely within the relative container */}
      <img
        src="/banner.png"
        alt="Logo Background"
        style={{
          position: 'absolute',
          width: '90%', // Adjust based on how you want the image to cover
          height: 'auto',
          top: '-15%', // Adjust to control how much the image overlaps with the text
          left: '50%',
          transform: 'translateX(-50%)', // Center the image horizontally
          zIndex: '-1', // Ensure the image stays behind the text
        }}
      />
      {/* Text positioned relatively on top of the image */}
      <div className="logo-text" style={{ position: 'relative', zIndex: '1', color: 'white' }}>Derple</div>
    </div>
  );
};

export default Logo;
