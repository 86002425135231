import "../styles.css";
import "../search.css";
import React, { useEffect } from "react";
import Logo from "../components/logo";
import SearchBox from "../components/searchbox";
import BoxMenuDrop from "../components/boxmenudrop";
import ProfileMenuDrop from "../components/profilemenudrop";
import { useNavigate } from "react-router-dom";
import { Content } from "../data/content";
import { Link } from "react-router-dom";

function Home() {
  const navigate = useNavigate();

  // These values will be shown in the search dropdown
  // The name property is the actual text and the value property is the link
  const options = [
    {
      name: "buy $DERP",
      value: "buy"
    },
    {
      name: "farm $DERP",
      value: "farm"
    },
    {
      name: "draw DeRPs",
      value: "draw"
    },
    {
      name: "everything about derp",
      value: "all"
    },
    {
      name: "about",
      value: "about"
    },
    { name: "news", value: "blog" },
    { name: "images", value: "images" },
    { name: "social", value: "social" }
  ];

  // Website search
  const searchWebsite = () => {
    let path = document.querySelector(".search-input").value;
    if (path) {
      navigate(path);
    }
  };

  useEffect(() => {
    let inputField = document.querySelector(".search-input");
    //Trigger search when the enter key is pressed
    inputField.addEventListener("keyup", function (event) {
      if (event.keyCode === 13) {
        event.preventDefault();
        searchWebsite();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // I'm Feeling Lucky search
  function feelingLucky() {
    let path = document.querySelector(".search-input").value;

    if (!path) {
      navigate(`/${options[Math.floor(Math.random() * options.length)].value}`);
      return;
    }

    /* Get all elements matching the search term */
    const item = Content.filter((item) => item.category === path);

    // Get the link of the first match
    // Redirect to first match, if it exists
    if (item[0]) {
      const url = item[0].link;
      window.location.href = url;
    } else if (path) {
      navigate(path);
    }
  }

  return (
    <div className="home main">
      <div className="top-menu">
        <span className="top-menu-item no-show-mobile">
          {" "}
          <a href="https://x.com/DeRP_xrpl"> Twitter/X </a>
        </span>
        {/* <span className="top-menu-item no-show-mobile">
          {" "}
          <a href="https://t.me/DeRPCommunity"> Telegram </a>
        </span> */}
        <BoxMenuDrop />
        <ProfileMenuDrop />
      </div>
      <div className="flex-center">
        <div className="search-container">
          <div className="frontpage-logo">
            <Logo />
          </div>
          <SearchBox options={options} />
          <div className="search-btns">
            <div className="btn-row">
              <input
                className="search-btn sw"
                type="button"
                value="Start DeRPing!"
                onClick={() => navigate('/buy')}
              />
              <a
                href="/farm"
                className="search-btn ifl"
              >
                Farm
              </a>
              <input
                className="search-btn ifl"
                type="button"
                value="I'm Feeling Lucky"
                onClick={feelingLucky}
              />
            </div>
            <div className="btn-row">
              <a
                href="https://xrpl.services/?issuer=rNsnnGTMDkcYjsshMCBon2kA4nKWb3UHWE&currency=4465525000000000000000000000000000000000&limit=1999999999.989482"
                className="search-btn ifl"
              >
                TrustLine
              </a>
              <a
                href="/draw"
                className="search-btn ifl"
              >
                Draw
              </a>
              <a
                href="/about"
                className="search-btn ifl"
              >
                About
              </a>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="country">
        When I DeRP you DeRP we DeRP
        </div>
        <div className="footer-links">
          <div className="footer-links-section">
            <Link to="/about#derponomics"> Tokenomics </Link>
            <Link to="/draw"> Draw </Link>
            <Link to="/blog"> Fake News</Link>
          </div>
          <div className="footer-links-section">
            <a href="https://firstledger.net/token/rNsnnGTMDkcYjsshMCBon2kA4nKWb3UHWE/4465525000000000000000000000000000000000#"> Buy On First Ledger </a>
            <a href="https://xpmarket.com/amm/pool/DeRP-rNsnnGTMDkcYjsshMCBon2kA4nKWb3UHWE/XRP/"> Buy On XP Market </a>
            {/* <div className="settings-dropdown">
              <button className="settings-dropbtn"> Settings </button>
              <div className="settings-dropdown-content">
                <a href="/">Link 1</a>
                <a href="/">Link 2</a>
                <a href="/">Link 3</a>
                <a className="dark-mode-btn" href="/">
                  Link 4
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;
