import tutcode from "../images/tutcode.png";
import enjeck from "../images/enjeck.png";
import gfolio from "../images/gfolio.png";
import jpg2svg from "../images/jpg2svg.png";
import gg from "../images/geo-guess.png";
import blobby from "../images/blobby.png";
import cav from "../images/CryptographyVisualizer.png"
import li from "../images/linkedIn-icon.png";
import gh from "../images/github-icon.png";
import email from "../images/gmail-icon.png";
import placeholder from "../images/bg1.png";

// Function to shuffle an array
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

// Create an array of image numbers
const imageNumbers = Array.from({ length: 50 }, (_, i) => i + 1); // [1, 2, ..., 50]
const shuffledImageNumbers = shuffleArray(imageNumbers); // Shuffle the array

export const ImagesContent = [
  {
    link: "",
    img: `/${shuffledImageNumbers[0]}.png`, // Randomized image link
    name: "@xrpcafe", // Replaced name
  },
  {
    link: "",
    img: `/${shuffledImageNumbers[1]}.png`, // Randomized image link
    name: "@xrpcafe", // Replaced name
  },
  {
    link: "",
    img: `/${shuffledImageNumbers[2]}.png`, // Randomized image link
    name: "@xrpcafe", // Replaced name
  },
  {
    link: "",
    img: `/${shuffledImageNumbers[3]}.png`, // Randomized image link
    name: "@xrpcafe", // Replaced name
  },
  {
    link: "",
    img: `/${shuffledImageNumbers[4]}.png`, // Randomized image link
    name: "@xrpcafe", // Replaced name
  },
  {
    link: "",
    img: `/${shuffledImageNumbers[5]}.png`, // Randomized image link
    name: "@xrpcafe", // Replaced name
  },
  {
    link: "",
    img: `/${shuffledImageNumbers[6]}.png`, // Randomized image link
    name: "@xrpcafe", // Replaced name
  },
  {
    link: "",
    img: `/${shuffledImageNumbers[7]}.png`, // Randomized image link
    name: "@xrpcafe", // Replaced name
  },
  {
    link: "",
    img: `/${shuffledImageNumbers[8]}.png`, // Randomized image link
    name: "@xrpcafe", // Replaced name
  },
  {
    link: "",
    img: `/${shuffledImageNumbers[9]}.png`, // Randomized image link
    name: "@xrpcafe", // Replaced name
  },
  {
    link: "",
    img: `/${shuffledImageNumbers[10]}.png`, // Randomized image link
    name: "@xrpcafe", // Replaced name
  },
  {
    link: "",
    img: `/${shuffledImageNumbers[11]}.png`, // Randomized image link
    name: "@xrpcafe", // Replaced name
  },
  {
    link: "",
    img: `/${shuffledImageNumbers[12]}.png`, // Randomized image link
    name: "@xrpcafe", // Replaced name
  },
  {
    link: "",
    img: `/${shuffledImageNumbers[13]}.png`, // Randomized image link
    name: "@xrpcafe", // Replaced name
  },
  {
    link: "",
    img: `/${shuffledImageNumbers[14]}.png`, // Randomized image link
    name: "@xrpcafe", // Replaced name
  },
  {
    link: "",
    img: `/${shuffledImageNumbers[15]}.png`, // Randomized image link
    name: "@xrpcafe", // Replaced name
  },
  {
    link: "",
    img: `/${shuffledImageNumbers[16]}.png`, // Randomized image link
    name: "@xrpcafe", // Replaced name
  },
  {
    link: "",
    img: `/${shuffledImageNumbers[17]}.png`, // Randomized image link
    name: "@xrpcafe", // Replaced name
  },
  {
    link: "",
    img: `/${shuffledImageNumbers[18]}.png`, // Randomized image link
    name: "@xrpcafe", // Replaced name
  },
  {
    link: "",
    img: `/${shuffledImageNumbers[19]}.png`, // Randomized image link
    name: "@xrpcafe", // Replaced name
  },
];
