import React, { useState } from "react";
import { Content } from "../data/content";
import Layout from "../components/header";
import AllResultsCard from "../components/allResultsCard";
import Header from "../components/header";
import Footer from "../components/footer";
import FilterMenu from "../components/filtermenu";
import tokenomics from "../images/derplogo.png";

function About() {
  const about = Content.filter((item) => item.category === "about");
  return (
    <div className="main">
      <Header />
      <FilterMenu />
      <div className="all-results-container">
        <p className="result-count">
          About {about.length} results (0.43 seconds)
        </p>
        <AllResultsCard results={about} />
        
        {/* Add the bullet points here above the image */}
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <h2 style={{ fontSize: '24px', fontWeight: 'bold' }}>DeRPonomics:</h2>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            <li style={{ fontSize: '20px' }}>• 70% initial LP (burnt)</li>
            <li style={{ fontSize: '20px' }}>• 20% DeRP AMM and monthly rewards</li>
            <li style={{ fontSize: '20px' }}>• 5% airdrop</li>
            <li style={{ fontSize: '20px' }}>• 5% DeRP Devs</li>
          </ul>
        </div>

        {/* Image positioned below the bullet points */}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '500px' }}>
          <img
            src={tokenomics}
            alt="profile"
            style={{ maxWidth: '100%', height: 'auto' }}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
export default About;
