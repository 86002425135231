import React from "react";
import { Content } from "../data/content";
import AllResultsCard from "../components/allResultsCard";
import Header from "../components/header";
import Footer from "../components/footer";
import FilterMenu from "../components/filtermenu";

function Buy() {
  const buy = Content.filter((item) => item.category === "buy");
  return (
    <div className="main">
      <Header />
      <FilterMenu />
      <div className="all-results-container">
        <p className="result-count">
          About {buy.length} results (0.57 seconds)
        </p>
        <AllResultsCard results={buy} />
      </div>
      <Footer />
    </div>
  );
}
export default Buy;
