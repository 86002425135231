import geolocation from "../images/geolocation-api.jpg";
import distanceTwoLoc from "../images/distance-two-locations.jpg";
import svgAdvantages from "../images/svg-advantages-and-disadvantages.jpg";
import jsIcon from "../images/js-icon.png";

export const BlogContent = [
  {
    link: "",
    img: "/1.png",
    icon: "https://pbs.twimg.com/profile_images/1849171627654918144/qdh9D5MN_400x400.jpg",
    name: "DeRP Rescues A Stranded Baby!",
    excerpt: `In a remarkable turn of events, the enigmatic DeRP has emerged as the unexpected savior in a heartwarming rescue mission involving a stranded baby. Witnesses were left in awe as DeRP swooped in to cradle and comfort the distressed infant until help arrived. "It was a surreal moment, like witnessing a miracle," remarked one astonished bystander, struggling to reconcile the absurdity with the gratitude felt for DeRP's intervention.
    As news of DeRP's heroic deed spread across digital realms, memes and praises flooded online forums, with users hailing DeRP as the epitome of courage and compassion. "Move aside, traditional superheroes! DeRP is the real MVP," quipped one Twitter user, encapsulating the whimsical essence of the moment. While questions linger about DeRP's origins and motivations, one thing is for certain: in a world where heroes come in all shapes and sizes, DeRP has proven that even the most unlikely candidates can rise to the occasion.`,
    category: "DeRP News",
    date: "3 hours ago"
  },
  {
    link: "",
    img: "/2.png",
    icon: "https://pbs.twimg.com/profile_images/1849171627654918144/qdh9D5MN_400x400.jpg",
    name: "DeRP Saves The Day",
    excerpt: `In a surprising twist of fate, DeRP has developed a software that can automatically translate any online argument into a harmonious discussion, effectively saving the day from internet trolls. Critics argue it just replaces heated words with pictures of cats, but many agree it's a step in the right direction.`,
    category: "DeRP News",
    date: "15 hours ago"
  },
  {
    link: "",
    img: "/3.png",
    icon: "https://pbs.twimg.com/profile_images/1849171627654918144/qdh9D5MN_400x400.jpg",
    name: "DeRP Making Strides In The Philanthropy World",
    excerpt: `DeRP's new initiative to solve world hunger involves creating a global network of food-sharing apps, where leftovers from restaurants are teleported directly to those in need using a mysterious, yet-to-be-revealed technology. Skeptics are hungry for proof, while supporters are just hungry.`,
    category: "DeRP News",
    date: "1 day ago"
  },
  {
    link: "",
    img: "/4.png",
    icon: "https://pbs.twimg.com/profile_images/1849171627654918144/qdh9D5MN_400x400.jpg",
    name: "DeRP Goes To The Moon!",
    excerpt: `DeRP's ambitious project to launch a series of Wi-Fi satellites around the moon to ensure astronauts never lose their internet connection has been met with mixed reactions. While some laud the initiative for keeping space travelers TikTok-accessible, others question if extraterrestrial vlogging is a necessary step for mankind.`,
    category: "DeRP News",
    date: "2 days ago"
  },
  {
    link: "",
    img: "/5.png",
    icon: "https://pbs.twimg.com/profile_images/1849171627654918144/qdh9D5MN_400x400.jpg",
    name: "DeRP seen at club with Zendaya",
    excerpt: `In a startling turn of events, the internet's favorite character, DeRP, was spotted living it up at an exclusive nightclub alongside Hollywood superstar Zendaya. Eyewitnesses couldn't believe their eyes as the dynamic duo danced the night away, with DeRP's arms flailing in excitement and Zendaya effortlessly stealing the spotlight. "It was like witnessing the ultimate crossover event," remarked one stunned club-goer, still processing the surreal scene.
    As photos of the unexpected pair flooded social media, memes and speculation ran rampant, with users concocting wild theories about the nature of their newfound friendship. "Move over, Brangelina! We have a new power couple in town," joked one Twitter user, capturing the absurdity of the situation. While the details of their rendezvous remain shrouded in mystery, one thing is certain: DeRP and Zendaya's night on the town has left the internet buzzing with delight and disbelief.`,
    category: "DeRP News",
    date: "3 days ago"
  },
  {
    link: "",
    img: "/6.png",
    icon: "https://pbs.twimg.com/profile_images/1849171627654918144/qdh9D5MN_400x400.jpg",
    name: "DeRP to star in new TV series",
    excerpt: `In a surprising move that has sent shockwaves through the entertainment industry, the beloved character, DeRP, has been announced as the star of an upcoming television series. Titled "The Adventures of DeRP," the show promises to take viewers on a whimsical journey through the quirky world of puppetry, with DeRP as the lovable protagonist. "It's about time we gave characters their rightful place in the spotlight," exclaimed one enthusiastic producer, envisioning DeRP's yarn-covered escapades captivating audiences worldwide.
    As news of DeRP's television debut spreads like wildfire, fans are already buzzing with anticipation, flooding social media with memes and fan theories about what adventures await their favorite character. "Finally, a show that truly understands the depth and complexity of character-based storytelling," quipped one Twitter user, capturing the tongue-in-cheek excitement surrounding DeRP's latest endeavor. While details about the series remain scarce, one thing is certain: with DeRP at the helm, viewers are in for a treat unlike anything they've ever seen before.`,
    category: "DeRP News",
    date: "4 days ago"
  },
  {
    link: "",
    img: "/7.png",
    icon: "https://pbs.twimg.com/profile_images/1849171627654918144/qdh9D5MN_400x400.jpg",
    name: "DeRP involved in affair with wife's boyfriend's girlfriend",
    excerpt: `In a scandal that has rocked the community, DeRP finds itself embroiled in a tabloid-worthy affair with the girlfriend of its wife's boyfriend. The revelation has sent shockwaves through digital forums, with users struggling to wrap their minds around the tangled web of relationships involving the beloved character. "I never thought I'd see the day when DeRP would make headlines for its romantic entanglements," lamented one bewildered fan in disbelief.
    As speculation runs rampant and memes flood social media, the drama surrounding DeRP's love life has become the hottest topic of discussion online. "Move over, soap operas! DeRP's love triangle is the juiciest storyline of the year," quipped one Twitter user, capturing the satirical absurdity of the situation. While DeRP remains tight-lipped about the scandal, one thing is certain: when it comes to matters of the heart, even DeRP isn't immune to the allure of forbidden romance.`,
    category: "DeRP News",
    date: "5 days ago"
  },
];
