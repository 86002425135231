import "./styles.css";
import "./search.css";
import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Redirect
} from "react-router-dom";
import About from "./pages/about"; // Ensure the component name starts with an uppercase letter
import works from "./pages/works";
import Home from "./pages/home";
import writing from "./pages/writing";
import Social from "./pages/social"; // Change this line: capitalize 'Social' and update the import
import AllResults from "./pages/allResults";
import NotFound from "./pages/notFound";
import ProjectsPage from "./pages/projectsPage";
import ImagesPage from "./pages/imagesPage";
import BlogPage from "./pages/blogPage";
import DrawPage from "./pages/drawPage";
import BuyPage from "./pages/buy";
import Farm from "./pages/farm";  // Import the Farm component

class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/all" element={<AllResults />} />
          <Route path="/about" element={<About />} />
          <Route path="/writing" element={<writing />} />
          <Route path="/works" element={<works />} />
          <Route path="/social" element={<Social />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/images" element={<ImagesPage />} />
          <Route path="/draw" element={<DrawPage />} />
          <Route path="/buy" element={<BuyPage />} />
          <Route path="/farm" element={<Farm />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    );
  }
}
export default App;
